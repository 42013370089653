import Shane from "../../../assets/images/Shane-Curran.png";
import Mary from "../../../assets/images/MARY-SHARRY.png";
import Nicky from "../../../assets/images/NICKY-DOYLE.png";
import Nina from "../../../assets/images/NINA-KILLEEN.png";
import Richard from "../../../assets/images/RICHARD-ROCHE.png";
import Ronan from "../../../assets/images/new.png";
export interface ProfileDataType {
  name: string;
  post: string;
  imgUrl: string;
}
export const profileData: ProfileDataType[] = [
  {
    name: "Shane Curran",
    post: "Director",
    imgUrl: Shane,
  },
  {
    name: "NICKY DOYLE",
    post: "Purchasing Manager",
    imgUrl: Nicky,
  },
  {
    name: "RICHARD ROCHE",
    post: "Commercial Director ",
    imgUrl: Richard,
  },
  {
    name: "NINA KILLEEN",
    post: "Information Manager",
    imgUrl: Nina,
  },
  {
    name: "MARY SHARRY",
    post: "Accounts",
    imgUrl: Mary,
  },
  {
    name: "Joe  McSwiney",
    post: "Operations Director",
    imgUrl: Ronan,
  },
];
