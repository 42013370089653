import { ReactComponent as EnergyfixLogoWhite } from "../../assets/logo-energyfix-white.svg";
import Container from "@shared/designComponents/Container";
import SeaiLogoSVG from "../../assets/seai-logo1.svg";
import {
  ABOUTUS_ROUTE,
  COST_GRANT_PATH,
  CUSTOMER_CHARTER_ROUTE,
  FAQS_ROUTE,
  RETROFIT_ROUTE,
  SERVICES_ROUTE,
} from "../../routes/routePaths";
import InfoText from "./InfoText";
import SocialLinks from "./SocialLinks";
import SubscriptionForm from "./SubscriptionForm";
import {
  CenterColStyled,
  EmailLink,
  FooterLinkStyled,
  FooterLinkTextStyled,
  FooterStyled,
  FormWrapperStyled,
  LeftColStyled,
  RightColStyled,
  SocialWrapperStyled,
  WrapperStyled,
} from "./style";
import { Image, Typography } from "@shared/designComponents";
import theme from "@shared/theme";

const Footer = () => {
  const rot13 = (str) => {
    return str.replace(/[a-zA-Z]/g, (c) =>
      String.fromCharCode(
        (c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
      )
    );
  };

  const decodedEmail = rot13("vasb@raretlsvk.vr");
  return (
    <FooterStyled>
      <Container enableMargin={false}>
        <WrapperStyled>
          <LeftColStyled>
            <EnergyfixLogoWhite />
            <FooterLinkTextStyled>
              Email us at {""}
              {/* <EmailLink to="mailto:info@energyfix.ie"> */}
              <EmailLink to={`mailto:${decodedEmail}`}>EnergyFix</EmailLink>
            </FooterLinkTextStyled>
            <div>
              <Image
                src={SeaiLogoSVG}
                style={{
                  objectFit: "contain",
                  maxWidth: "230px",
                }}
                height={"100%"}
                width="100%"
                alt="SEAI Logo"
              />
            </div>
            <Typography size="xs" color={theme.colors.disable}>
              Grants offered are provided by the Government of Ireland through
              the Sustainable Energy Authority of Ireland (SEAI).
            </Typography>
          </LeftColStyled>
          <CenterColStyled>
            <FooterLinkTextStyled fontWeight="semibold" $noHover $isTitle>
              COMPANY
            </FooterLinkTextStyled>
            <FooterLinkStyled to={ABOUTUS_ROUTE}>
              <FooterLinkTextStyled>About us</FooterLinkTextStyled>
            </FooterLinkStyled>
            <FooterLinkStyled to={RETROFIT_ROUTE}>
              <FooterLinkTextStyled>What is Retrofitting?</FooterLinkTextStyled>
            </FooterLinkStyled>
            <FooterLinkStyled to={SERVICES_ROUTE}>
              <FooterLinkTextStyled>Our Services</FooterLinkTextStyled>
            </FooterLinkStyled>
            <FooterLinkStyled to={COST_GRANT_PATH}>
              <FooterLinkTextStyled>Cost and Grants</FooterLinkTextStyled>
            </FooterLinkStyled>
            {/* <FooterLinkStyled to="#">
              <FooterLinkTextStyled>Careers</FooterLinkTextStyled>
            </FooterLinkStyled> */}
            <FooterLinkStyled to={CUSTOMER_CHARTER_ROUTE}>
              <FooterLinkTextStyled>Customer Charter</FooterLinkTextStyled>
            </FooterLinkStyled>
            <FooterLinkStyled to={FAQS_ROUTE}>
              <FooterLinkTextStyled>FAQs</FooterLinkTextStyled>
            </FooterLinkStyled>
          </CenterColStyled>
          <RightColStyled>
            {/* <FormWrapperStyled>
              <div>
                <FooterLinkTextStyled fontWeight="semibold" $noHover $isTitle>
                  Want to learn about the latest Energy Retrofit news?
                </FooterLinkTextStyled>
                <FooterLinkTextStyled $noHover style={{ marginTop: "8px" }}>
                  Sign up to our newsletter
                </FooterLinkTextStyled>
              </div>
              <SubscriptionForm />
            </FormWrapperStyled> */}
            <SocialWrapperStyled>
              <SocialLinks />
              <InfoText />
            </SocialWrapperStyled>
          </RightColStyled>
        </WrapperStyled>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
