import { useRef, useState } from "react";
import Ellipse from "../../assets/Ellipse-117.png";
import {
  Button,
  ColorText,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import Container from "@shared/designComponents/Container";
import theme from "@shared/theme";
import { termsData, termsType } from "./TermsData";
import { MainWrapperStyle, TermsWrapperStyle } from "./style";
import { Helmet } from "react-helmet-async";

const Terms: React.FC = () => {
  const [activeTerms, setActiveTerms] = useState<number>(1);

  const handleClick = (id: number) => {
    setActiveTerms(id);
    const ref = refs[id];

    window.scrollTo({
      top: ref.current?.offsetTop,
      behavior: "smooth",
    });
  };

  const refs: { [key: number]: React.RefObject<HTMLDivElement> } =
    termsData.reduce((acc, item) => {
      acc[item.id] = useRef<HTMLDivElement>(null);
      return acc;
    }, {} as { [key: number]: React.RefObject<HTMLDivElement> });

  return (
    <>
      <Helmet>
        <title>EnergyFix - Term of use / Privacy Policy</title>
        <meta
          name="description"
          content="EnergyFix outlines GDPR-compliant data retention policies, emphasizing confidentiality, security, and proper disposal of personal data."
        />
        <link rel="canonical" href="/terms" />
      </Helmet>
      <Container marginVertical={50}>
        <MainWrapperStyle>
          <div className="large-device-content">
            <Typography
              size="md"
              fontWeight="medium"
              color={theme.colors.footerBlack}
            >
              Table of contents
            </Typography>
            {termsData?.map((item) => (
              <Button
                variant="link"
                onClick={() => handleClick(item?.id)}
                key={item.id}
                style={{ padding: "0px" }}
              >
                <Typography
                  size="sm"
                  fontWeight="normal"
                  color={
                    activeTerms === item.id
                      ? theme.colors.primary
                      : theme.colors.lightText
                  }
                >
                  {item?.title}
                </Typography>
              </Button>
            ))}
          </div>
          <div>
            <SectionTitle className="section-title">
              <ColorText color={theme.colors.secondary}>
                Term of use /{" "}
              </ColorText>
              <ColorText color={theme.colors.primary}>Privacy Policy</ColorText>
            </SectionTitle>
            <TermsWrapperStyle>
              <div className="container">
                <Typography
                  size="md"
                  fontWeight="medium"
                  color={theme.colors.footerBlack}
                >
                  Table of contents
                </Typography>
                {termsData?.map((item) => (
                  <Button
                    variant="link"
                    onClick={() => handleClick(item?.id)}
                    key={item?.id}
                    style={{ padding: "0px" }}
                  >
                    <Typography
                      size="sm"
                      fontWeight="normal"
                      color={
                        activeTerms === item?.id
                          ? theme.colors.primary
                          : theme.colors.lightText
                      }
                    >
                      {item?.title}
                    </Typography>
                  </Button>
                ))}
              </div>
            </TermsWrapperStyle>
            <div className="content">
              {termsData.map((item: termsType) => (
                <div
                  key={item?.id}
                  className="title-content"
                  ref={refs[item?.id]}
                >
                  <Typography
                    size="lg"
                    fontWeight="normal"
                    color={theme.colors.footerBlack}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    size="sm"
                    fontWeight="light"
                    color={theme.colors.lightText}
                  >
                    {item?.content}
                    <div className="policy-content">
                      {item?.desc?.map((content) => (
                        <div key={content?.id} className="single-policy">
                          <div className="title">
                            <Typography
                              fontWeight="semibold"
                              color={theme.colors.primary}
                            >
                              0{content?.id}.{" "}
                            </Typography>
                            <Typography
                              fontWeight="normal"
                              color={theme.colors.lightText}
                            >
                              {content?.title}
                            </Typography>
                          </div>
                          {/* {content?.data?.map((item, idx) => (
                            <div key={idx} className="img-content">
                              <img
                                src={Ellipse}
                                alt="Ellipse"
                                width={8}
                                height={8}
                              />
                              <Typography
                                key={idx}
                                size="sm"
                                color={theme.colors.lightText}
                              >
                                {item?.content}
                              </Typography>
                            </div>
                          ))} */}

                          {content?.data?.map((item, idx) => (
                            <div key={idx} className="img-content">
                              <img
                                src={Ellipse}
                                alt="Ellipse"
                                width={8}
                                height={8}
                              />
                              <Typography
                                key={idx}
                                size="sm"
                                color={theme.colors.lightText}
                              >
                                {item?.content.includes(
                                  "eoghain@energyfix.ie"
                                ) ? (
                                  <>
                                    {
                                      item.content.split(
                                        "eoghain@energyfix.ie"
                                      )[0]
                                    }
                                    <a
                                      href="mailto:eoghain@energyfix.ie"
                                      style={{ color: theme.colors.primary }}
                                    >
                                      eoghain@energyfix.ie
                                    </a>
                                    {
                                      item.content.split(
                                        "eoghain@energyfix.ie"
                                      )[1]
                                    }
                                  </>
                                ) : (
                                  item?.content
                                )}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </MainWrapperStyle>
      </Container>
    </>
  );
};

export default Terms;
