export interface dataType {
  content: string;
}
export interface policyType {
  id: number;
  title: string;
  data: dataType[];
}
export interface termsType {
  title: string;
  id: number;
  content?: string;
  desc?: policyType[];
}

export const termsData: termsType[] = [
  {
    title: "Introduction",
    id: 1,
    content:
      "We at EnergyFix have produced this document retention policy to outline the guidelines for the retention and disposal of personal data in compliance with the General Data Protection Regulation (GDPR). We are committed to maintaining the highest standards when it comes to our obligations under GDPR.",
  },
  {
    title: "Definition",
    id: 2,
    content:
      "Personal data is any information relating to an identified or identifiable individual. This includes but is not limited to names, addresses, email addresses, and phone numbers.",
  },
  {
    title: "Policy",
    id: 3,
    desc: [
      {
        id: 1,
        title: "Data Collection and Processing",
        data: [
          {
            content:
              "Personal data will only be retained for 7 years to fulfill the purposes for which it was collected.",
          },
          {
            content:
              "When personal data is no longer required, it will be securely deleted.",
          },
          {
            content:
              "We will periodically review the need for retaining personal data and dispose of data when it is no longer needed.",
          },
        ],
      },
      {
        id: 3,
        title: "Data Disposal",
        data: [
          {
            content:
              " Personal data will be disposed of in a secure and confidential manner.",
          },
          {
            content:
              "We will ensure that all copies of the data are deleted and that it cannot be recovered.",
          },
          {
            content:
              " All hard copies of personal data will be shredded or incinerated appropriately.",
          },
        ],
      },
      {
        id: 4,
        title: "Data Access",
        data: [
          {
            content:
              "  Access to personal data will be restricted to authorized personnel only.",
          },
          {
            content:
              "Authorization will be granted on a need-to-know basis only.",
          },
          {
            content:
              "We will monitor access to personal data and ensure that only authorized personnel have access to it.",
          },
        ],
      },
      {
        id: 5,
        title: "Data Security",
        data: [
          {
            content:
              " We will take appropriate technical and organizational measures to protect personal data from unauthorized access, alteration, disclosure, or destruction.",
          },
          {
            content:
              "Personal data will be secured using encryption or other appropriate security measures.",
          },
          {
            content:
              " We will ensure that third-party processors are also GDPR compliant.",
          },
        ],
      },
      {
        id: 6,
        title: "Data Breach",
        data: [
          {
            content:
              " In the event of a data breach, we will immediately inform the supervisory authority, Eoghain Ryan at eoghain@energyfix.ie.",
          },
          {
            content:
              "We will also inform the affected individuals without undue delay.",
          },
          {
            content:
              " We will take appropriate measures to mitigate the impact of the data breach.",
          },
        ],
      },
    ],
  },
  {
    title: "Conclusion",
    id: 4,
    content:
      "We are committed to complying with the GDPR and maintaining the confidentiality and security of personal data. This retention policy will be reviewed regularly and updated as necessary to ensure continued compliance with GDPR requirements.",
  },
];
